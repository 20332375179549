import React from "react";

//Importing Assets
import pp from '../Assets/pp.jpeg'

class AboutMe extends React.Component {
  render(){
    return (
      <div id='aboutme' className="col my-5 p-0" style={{backgroundColor:'white', paddingTop:20, paddingBottom:20}}>
        <div className="col-12 d-flex justify-content-center">
          <h3><b>About Me</b></h3>
        </div>
        <div className="col mt-5 d-flex justify-content-center">
          <img src={pp} alt="Profile" className="rounded-circle" style={{height:200}}/>
        </div>

        <div className="col-12 d-flex justify-content-center">
          <hr className="m-5" style={{width:500}}/>
        </div>

        <div className="col-12 px-5 d-flex justify-content-center" style={{textAlign:'center'}}>
          <h4 className="col-lg-6 col-sm-11" style={{fontSize:20}}>I'm a 23 years old Robotics Engineering student from Brazil. Since I was a kid, electronics and programming were my passion which I still keep on working to this day. I am a curiosity-driven person who loves to DIY everything in life. Let's make something!</h4>
        </div>
      </div>
    );
  }
}

export default AboutMe;