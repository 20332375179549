import React from "react";

class Header extends React.Component {
  render(){
    return (
      <div id='footer' className="container-fluid p-0 m-0">
      <footer className="p-4 bg-dark">
        <p className="p-0 m-0 text-center">
          <a href='https://www.facebook.com/diego.costa.90857' target='_blank'><img src={`images/facebook.png`}  alt='image' style={{height:32, cursor:'pointer', marginRight:10, marginBottom:20, marginTop:20}} /></a>
          <a href='https://www.instagram.com/diego.serodio/' target='_blank'><img src={`images/instagram.png`} alt='image' style={{height:32, cursor:'pointer', marginRight:10, marginBottom:20, marginTop:20}} /></a>
          <a href='https://www.linkedin.com/in/diego-serodio-costa/' target='_blank'><img src={`images/linkedin.png`} alt='image' style={{height:32, cursor:'pointer', marginRight:10, marginBottom:20, marginTop:20}} /></a>
          <a href='https://github.com/diegoserodio' target='_blank'><img src={`images/github.png`} alt='image' style={{height:32, cursor:'pointer', marginRight:10, marginBottom:20, marginTop:20}} /></a>
        </p>
        <p className="p-0 m-0 text-center" style={{color:'white'}}>
          ®2022 v1.0 - Diego Serodio Costa | All rights reserved
        </p>
        <p className="p-0 m-0 text-center" style={{color:'white'}}>
          Icons: Freepik from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> | IconsBox from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </p>
      </footer>
    </div>
    );
  }
}

export default Header;