import React from "react";

class Navbar extends React.Component {
  render(){
    return (
      <ul className="nav bg-dark justify-content-end">
        <a href="#aboutme">
          <li className="btn nav-item menu-item">
            <div className="nav-link active" style={{color:'white'}}>About me</div>
          </li>
        </a>
        <a href="#timeline">
          <li className="btn nav-item menu-item">
            <div className="nav-link active" style={{color:'white'}}>Timeline</div>
          </li>
        </a>
        <a href="#footer">
          <li className="btn nav-item menu-item">
            <div className="nav-link active" style={{color:'white'}}>Contact</div>
          </li>
        </a>
      </ul>
    );
  }
}

export default Navbar;