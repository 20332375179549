import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'

//Importing Screens
import Header from './Pages/Header'
import AboutMe from './Pages/AboutMe'
import Timeline from './Pages/Timeline'
import TImelineTest from './Pages/Timeline-test'
import Footer from './Pages/Footer'

//Importing Components
import Navbar from './Components/Navbar'
import ScrollToTop from './Components/ScrollToTop'

ReactDOM.render(
  <React.StrictMode>
    <div className="container-fluid m-0 p-0"  style={{overflowX:"hidden"}}>
      <Header />
      <Navbar />
      <AboutMe />
      <TImelineTest />
      <Footer/>
      <ScrollToTop />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
