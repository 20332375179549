import React from "react";

//Importing style
import '../Styles/Header.css'

//Importing assets
import background from '../Assets/back_header.png'

class Header extends React.Component {
  render(){
    return (
      <div className="row m-0 p-0 header-container">

        {/*----- Background -----*/}
        <div className="position-absolute m-0 p-0 header-container back-image">
          {/* <img  className="header-container" src={background} alt="Background" /> */}
        </div>
        <div className="position-absolute m-0 p-0 header-container img-filter" />
        
        {/*----- Content -----*/}
        <div className="col-12 position-absolute d-flex align-items-center justify-content-center p-5 back-img">
          <div className="col-lg-4 col-sm-10 d-flex flex-column align-items-center" style={{zIndex: 2}}>
            <h1 className="mb-2" style={{color:'white', textAlign:'center'}}>Hello, I'm <b>Diego</b>.</h1>
            <h5 className="mb-5" style={{color:'white', textAlign:'center'}}>Maker & Hobbist / Engineering Student at UFABC</h5>
            <a href="https://drive.google.com/file/d/1RWeuuWKt0HLb9jWk2XkX36iMv8M3XNWi/view?usp=sharing" target="_blank" rel="noreferrer" className="col-lg-5 col-sm-8 btn btn-outline-light">Download my CV</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;