import React, { useEffect, useState } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div 
    style={{
      position: 'fixed', 
      bottom: '0.5rem',
      right: '0.5rem',
      animation: 'fadeIn 700ms ease-in-out 1s both',
      cursor: 'pointer'
    }}>
      {isVisible && 
        <div className="btn btn-dark" onClick={scrollToTop} style={{fontSize:20}}>
          goto top;
        </div>}
    </div>
  );
}
