import React from "react";
import ReactPlayer from "react-player";

//Importing style
import '../Styles/Timeline.css'

// Importing utilities
import { renderContent } from '../utilities'

// Importing content
import content from '../Content/timeline_content.json'

class TimelineTest extends React.Component {
  constructor(props){
    super(props);
  }

  renderContentDesktop(){
    let result = [];
    let count = 0
    for(let el in content){
      if(content[el].type === "year"){
          result.push(<this.Year key={el} year={content[el].label} />)
      } 
      else if(content[el].type === "milestone"){
        if(count % 2 === 0){
          result.push(
            <this.Milestone key={el}
            left={(
            <div className="row d-flex flex-column">
              <h1 style={{textAlign:'left'}}><b>{content[el].title}</b></h1>
              <h4 style={{textAlign:'left', fontSize:20}} dangerouslySetInnerHTML={ {__html: content[el].description} }></h4>
            </div>
            )}
            right={content[el].video ? (
              <ReactPlayer
                url={content[el].video}
              />
            ):(
              <img src={content[el].image} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
            )}
            />)
        } else{
          result.push(
            <this.Milestone key={el}
            right={(
            <div className="row d-flex flex-column">
              <h1 style={{textAlign:'left'}}><b>{content[el].title}</b></h1>
              <h4 style={{textAlign:'left', fontSize:20}} dangerouslySetInnerHTML={ {__html: content[el].description} }></h4>
            </div>
            )}
            left={content[el].video ? (
              <ReactPlayer
                url={content[el].video}
              />
            ):(
              <img src={content[el].image} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
            )}
            />)
        }
        count += 1
      }
    }
    return result;
  }

  renderContentMobile(){
    let result = [];
    for(let el in content){
      if(content[el].type === "year"){
        result.push(<this.YearMobile key={el} year={content[el].label} />)
      } 
      else if(content[el].type === "milestone"){
        result.push(
          <this.MilestoneMobile key={el}
          image={content[el].video ? (
            <ReactPlayer
              className="img-fluid"
              url={content[el].video}
            />
          ):(
            <img src={content[el].image} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
          )}
          title={(
            <div className="row d-flex flex-column">
              <h1 style={{textAlign:'left'}}><b>{content[el].title}</b></h1>
            </div>
          )}
          description={(
            <div className="row d-flex flex-column">
            <h4 style={{textAlign:'left', fontSize:20}} dangerouslySetInnerHTML={ {__html: content[el].description} }></h4>
            </div>
          )}
          />)
      }
    }
    return result;
  }

  Year(props){
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-5 pl-5" style={{height:210}} />
        <div className="col-2 d-flex flex-column align-items-center" style={{height:210, position:'relative'}}>
          <div 
            className="d-flex justify-content-center align-items-center" 
            style={{
              width:110, 
              height:110, 
              borderRadius:100,
              borderStyle:'solid',
              borderWidth:3,
              borderColor:'#1B264F',
              backgroundColor:'#1B264F',
              color:'white',
              position:'absolute',
              top:0,
              left: 0, 
              right: 0, 
              marginLeft: 'auto', 
              marginRight: 'auto',
              fontSize:35
            }} 
          >
            <b>{props.year}</b>
          </div>
          <div
            style={{
              width:3, 
              height:100,
              backgroundColor:'#1B264F',
              position:'absolute',
              bottom:0,
              left:"50%"
            }} 
          />
        </div>
        <div className="col-5 pr-5" style={{height:210}} />
      </div>
    )
  }

  YearMobile(props){
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-1" style={{height:"auto"}} />
        <div className="col-2 d-flex flex-column align-items-center" style={{height:80, position:'relative'}}>
          <div 
            className="d-flex justify-content-center align-items-center" 
            style={{
              width:60,
              height:60,
              borderRadius:100,
              borderStyle:'solid',
              borderWidth:3,
              borderColor:'#1B264F',
              backgroundColor:'#1B264F',
              color:'white',
              position:'absolute',
              top:0,
              left: 0, 
              right: 0, 
              marginLeft: 'auto', 
              marginRight: 'auto',
              fontSize:25
            }} 
          >
            <b>{props.year}</b>
          </div>
          <div
            style={{
              width:3, 
              height:20,
              backgroundColor:'#1B264F',
              position:'absolute',
              left: 0, 
              right: 0, 
              bottom: 0,
              marginLeft: 'auto', 
              marginRight: 'auto',
            }} 
          />
        </div>
        <div className="col-9 pr-5" style={{height:80}} />
      </div>
    )
  }
  
  Milestone(props){
    return (
      <div className="row d-flex justify-content-center" style={{height:'auto', minHeight:500}}>
        <div className="col-5 d-flex justify-content-end align-items-center">
          {props.left}
        </div>
        <div className="col-1 d-flex flex-column align-items-center" style={{height:"100%", position:'relative'}}>
          <div
            style={{
              width:3, 
              height:"100%",
              backgroundColor:'#1B264F',
              position:'absolute',
              top:0,
              left:"50%"
            }} 
          />
          <div 
            className="d-flex justify-content-center align-items-center" 
            style={{
              width:50, 
              height:50, 
              borderRadius:100,
              borderStyle:'solid',
              borderWidth:3,
              borderColor:'#1B264F',
              backgroundColor:'#1B264F',
              position:'absolute',
              top:0,
              bottom:0,
              left: 0, 
              right: 0, 
              marginLeft: 'auto', 
              marginRight: 'auto',
              marginTop:'auto',
              marginBottom:'auto'
            }} 
          />
        </div>
        <div className="col-5 px-5 d-flex justify-content-start align-items-center">
          {props.right}
        </div>
      </div>
    )
  }

  MilestoneMobile(props){
    return (
      <div className="row d-flex justify-content-center" style={{height:'auto', minHeight:500}}>
        <div className="col-1" style={{height:"100%"}} />
        <div className="col-2 d-flex flex-column align-items-center" style={{height:"auto", position:'relative'}}>
          <div
            style={{
              width:3, 
              height:"100%",
              backgroundColor:'#1B264F',
              position:'absolute',
              top:0,
              left: 0, 
              right: 0, 
              marginLeft: 'auto', 
              marginRight: 'auto',
            }} 
          />
        </div>
        <div className="col-9 d-flex flex-column justify-content-end align-items-center">
          {props.title}
          {props.image}
          <br/>
          {props.description}
        </div>
      </div>
    )
  }

  render(){
    return(
      <>
      <div id='timeline' className="row d-flex justify-content-center tm-desktop">
        {this.renderContentDesktop()}
      </div>
      <div className="row d-flex justify-content-center tm-mobile">
        {this.renderContentMobile()}
      </div>
      </>
    )
  }

  // render(){
  //   return (
  //     <>
  //     <div id='timeline' className="row d-flex justify-content-center tm-desktop">
  //       <this.Year year="2022" />
  //       <this.Milestone
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Gesture-Controlled Self Service Kiosk</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>At the end of 2021, I engaged on the AMD-Xilinx Adaptive Computing Challenge to solve real-world problems using adaptive computing. My project idea consisted on Gesture-Controlled Self Service Kiosk. This device can be placed in public spaces (E.g.: Malls) so people can use it for services in the establishment. With a touchless kiosk, people can check in, look for the available products and make a request without the need to physically interact with a screen, which is cheaper and safer than nowadays public kiosks.<br /><br /> Xilinx sent me their AMD-Xilinx Kria KV260 Vision AI Starter Kit as free-hardware so I could develop my project. KV360 consists of a FPGA Sytem On Module (SOM) built for advanced vision application development. Working on this project I had the opportunity to learn more about FPGAs and using Tensorflow for Computing Vision Apps. You can check my project submission's page in <a href="https://empower.vision" target="_blank">here</a>.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/hacksterio.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/ovove.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Egg Quality Detector</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>At my Unified Engineering class at university, as the final project we had to develop a product that could solve a daily problem, all of this while also applying the CDIO Framework! My group and I then decided to make an Egg Quality Detector: A simple machine that could quickly tell if an egg is good or not for human consumption.<br /><br />So we fabricated the prototype, consisting of an 1W LED shining throught an egg and a LDR on the other end. The amount of light that pass throught varies along with the rotteness of the egg. I was responsible for building the prototype's hardware and software. As this was a relatively simple project I saw an opportunity to use a STM32 bluepill board and then program it using RTOS and STM32CUBEIDE.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Year year="2021" />
  //       <this.Milestone
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Chair at IEEE UFABC Student Branch</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>By the end of 2020 and Beginning of 2021 I became chair of the IEEE UFABC Student Branch. For that, I was responsible for managing the technical and non-technical activities from the seven different Student Chapters that we had (each Chapter represents a IEEE Society and has an unique technical focus). <br /><br /> The duration of my term as the Branch leader took the whole year which has brought me many challenges as well as life-long lessons. During my tenure I had the opportunity to organize and/or conduct webinars to approximate the university students to the companies (made possible by our partnership with <a href="https://empower.vision" target="_blank">Empower Talents</a>). I also was able an to work on the Branch internal organization by setting up regular meetings and hangouts. Collaboration with other student groups and the University's administration was also crutial to participate in events and annual fairs. <br /><br /> It was really a challenging year for me, taking into account my responsabilities facing the the Student Branch's chair position, along with my internship and other projects. However, all of this reflected on both: better time management skills and task setting skills for myself.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/ieee_meeting.jpeg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/sas_2.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>SAS Institute Internship</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>By december of 2020 I got an offer for an internship at SAS Institute, a World Wide software company that is focused on Data Analytics. I officialy started working at SAS by January 5th of the following year, and since then I've already had wonderful experiences and countless growth opportunities (both personal and professional growth). <br /><br /> At SAS I'm part of the Digital Transformation and Innovation team, in which we're responsible for developing assets (e.g Demos, documentation) and spreading awareness about new technologies trends, from inside or outside of SAS. Thus, we work on exploring technologies, tools, frameworks and commercial strategies for Data Analysis, IoT, Edge Computing, Computer Vision and NLP, aimed at incorporating these into the company's activities in Latin America.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Milestone 
  //         right={(
  //           <img src={`images/3d_printer_1.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>3D Printer Project</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>As I was excited about adding more CAD/CAM to my personal projects and also taking into account the absence of a 3D printer available for use in my hometown. I decided to build my own DIY Low-cost 3D Printer. It shouldn't be too difficult as I had already seeing a lot of these 3D printers beeing made from old DVD Drivers and Arduino on Youtube. <br/><br/>So in January of 2021 I finished my first mini 3D Printer using mostly recycled materials. The structure was made of wood and the stepper motors were recycled from old DVD/CD drivers, for the electronics I used an Arduino Mega together with a RAMPS V4 board and standart A4988 stepper motor drivers. <br/><br/>For the software part, the project was based on the Marlin's firmware for interpreting the G-Code and controlling the RAMPS board. The slicer that worked better for me was Cura that besides being Open-Source, it allows you to setup the print with a variety of different settings (like Adherence Layer and Filling Pattern). Finally, to close up the technology stack I used Pronterface to send the G-Code commands to the Arduino.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Year year="2020" />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/ras2019.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
              
  //             <h1 style={{textAlign:'left'}}><b>Chair at IEEE Robotics and Automation Society</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>Starting fresh the 2020's year, I was at the beginning of my tenure as the Chair for the IEEE Robotics and Automation Student Branch Chapter in my University. In this chapter, we perform technical and social activities that involve all the subjects related to robotics and automation: Microcontrollers, IoT, Computer Vision, Programming, Electronics, etc. <br/><br/>As the active chair, I was able to manage and as well to participate in the majority of our technical projects, besides organizing non-technical activities like webinars, workshops and courses. Furthermore, I had the opportunity to represent the student branch on various events from our University and from IEEE (E.g: RSS - South Section Student Branches Meeting).</h4>
              
  //           </div>
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>COVIData</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>At the beginning of the 2020 quarantine, together with colleagues and professors, I started the COVIData project. A platform that helps with the identification of suspected cases of the new Coronavirus in the Great ABC Paulista region. This project was carried out in partnership with UFABC and the Intermunicipal Consortium of the Great ABC. <br/><br/>In this same project, I developed the mobile app "COVIData em Campo", along with the city hall of São Bernardo do Campo to carry out household surveys and Coronavirus tests to the city's population. Finally, a third application we developed under the COVIData's umbrella, was an internal portal for use by the UFABC's staff and students in order to perform Coronavirus tests and to report symptoms.<br/><br/>All of these projects were a great success reaching about 8,000 people from the ABC's Region, helping on gathering and analysing data about the pandemic on these cities, and also spreading knowledge about safety measures to those who most needed it. Besides that, the usage of the platform internally at UFABC, collected some crutial data about the pandemic status on the university's community, which itself contributed to the decision-making process for the university's administration.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/covidata.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/robotieee.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>RobotIEEE</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>The Robotics Competitions and Science Olimpics that I've participated during my High School years had a big impact on shaping who I am today. I have it clear in my mind that getting a degree on robotics engineering and making the kind of projects I make were heavily influenced by the Hobbies and extracurricular activities I took during my time at school. <br/><br/>However, at college I realized that these kind of opportunities weren't available for a lot of public schools in Brazil, so I decided to do something about it. So, along with some of my friends at IEEE, and also building on top of another school-partnership project we've made on the previous year (see AstroIEEE), we developed a project called RobotIEEE: in which we trained High Schools students from Amaral Wagner public school, to be able to participate on the Brazilian Robotics Olimpics (OBR).<br/><br/>Therefore, we developed didactic materials tackling on every subject covered by the theorical tests on the competition (Physics, Mathematics, Biology, Phylosophy, ...), and we've also made content/webinars about practical robotics knowledge focused on building and as well as coding a Line-Follower robot for the practical part of the competition.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Shawee MegaHack 3.0</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>Looking to participate on my first Hackathon, I came across with one of the biggest hackathons in Brazil: The Mega Hack - organized by Shawee. In this competition, we faced the Ambev's challenge on how to stand out as a brand and attract consumers back to bars. Basically, our challenge was make a MVP that would help to innovate and differentiate Ambev by creating immersive and unique experiences that go beyond product and take the spirit of the brand, understanding who the consumers are, their behavior and their journey. <br/><br/>With this challenge in mind, me and my team were able to develop a fully functional mobile app (Android & IPhone) in which customers could scan the barcode from Ambev's products and retrieve a handful of information about it. With the app, anyone could check the ingredients of the product, nutritional information or even recommendations of other beverages based on the customer's preferences.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/megahack.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/ufair.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>NASA Space Apps Hackathon</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>The second Hackathon I've ever participated was the International Space Apps Challenge from NASA. This version of the 2020 challenge was focused on finding creative sollutions and projects to take the huge amount of public environmental data from NASA datasets, and make it easier to access by researchers, companies and the general public. <br/><br/>Our project won the Global Finalist award and its purpose was to demonstrate the correlation between our actions in everyday life and the atmospheric pollution on a local and global scale. For that we developed a website using Streamlit (web framework for data science websites using Python), in which anyone could choose between two seemingly unrelated parameters (E.g: The amount of CO2 on the atmosphere and the number of reported floodings), then check how they correlate making use of different types of visualizations - Heat maps, Bubble maps and Correlation matrices.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>XMas Tree: A Holiday's project</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>In order to start my <a href="#">Instructables' portfolio</a>, I made this simple and fun project for the Holidays in 2020. The "ArduXmas" is a Bluetooth controlled Christmas tree which uses some Neopixel LEDs to create lighting animations, just like a regular tree only you can control it with your smartphone! <br/><br/> This project uses a Arduino Nano for controling the lights; a HC-06 Bluetooth module for receiving commands; WS2812b Neopixel LED strip for lighting and the Blynk app that is well-known for developing IoT mobile applications. You can check more about how I made this project on my <a href="#">Instructables page</a>.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <ReactPlayer
  //             url="https://www.youtube.com/watch?v=amsoag3gSV0"
  //           />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/atable_anim.gif`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>ATable: The interactive periodic table</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>Following the IoT trend and thinking about what to do with the remaining Neopixel LEDs from my previous project, I've decided to build an interactive Periodic Table as my final project of the year! I was always facinated by the amount of 'hidden' information the periodic table contains simply by organizing its elements into rows, columns, families and groups. So in this project, I thought it would be really cool if I could light up each element individually and create heat-maps/animations in order to show various properties like: Electronegativity, Atomic mass, Phase transition, Year of Discovery, etc. <br/><br/> To make this 'ATable' I've used a ESP32 as the project's brain. The ESP32 is a powerful NodeMCU-based 32-bit microcontroller that has built-in wifi and bluetooth, making it the perfect choice for IoT applications! To control the table, the microcontroller would create its own wifi network in which you could connect using your phone or computer, and then access a HTML webpage that I built in order to choose between one of various animations for the ATable to display.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Year year="2019" />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>IEEE National Student Branches Meeting</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>At this point, since 2018, I've participated in a variety of projects and activities in my IEEE University's Student Branch. The RNR (Student Branches National Meeting) is an annual event in which IEEE groups from all over the country gather up to share experiences, participate on contests, collaborate and learn together. <br/><br/> At this 2019-edition, I had the opportunity to get to know a lot of interesting people from other student branches, as well as their projects and ideas. At this meeting, we also received a lot of awards regarding our own Success Cases, a couple of which I've had the opportunity to contribute a great deal on that year.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/rnr2019.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Otto Robot</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>At this point, since 2018, I've participated in a variety of projects and activities in my IEEE University's Student Branch. The RNR (Student Branches National Meeting) is an annual event in which IEEE groups from all over the country gather up to share experiences, participate on contests, collaborate and learn together. <br/><br/> At this 2019-edition, I had the opportunity to get to know a lot of interesting people from other student branches, as well as their projects and ideas. At this meeting, we also received a lot of awards regarding our own Success Cases, a couple of which I've had the opportunity to contribute a great deal on that year.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/otto.gif`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/astroieee.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>AstroIEEE</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>Just like the other project I've mentioned (RobotIEEE) this one was heavily influenced by my own experiences in High School competitions. This project consisted of mentoring students from a public school in Santo André to be able to participate in the Brazilian Astronomy Olympics (OBA) and  the Brazilian Rocket Festival (MOBFOG). <br/><br/> In this project I was able to conduct weekly workshops and classes for the High Schoolers, teaching them some basic concepts for building a high-quality Vinager and Baking Soda Rockets. I teached the fundamentals about: Stoichiometry for calculating the chemical reactions; Aerodinamics for the various Rocket parts; Safety & DIY skills for them to be able to build their rockets using only household materials. This project was a success on its own. One of the teams was able to get to the National phase of the festival, getting the chance to travel to the Rio de Janeiro's state and competing with teams from all over the country.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Research on Graphite & Graphene Electrolithic Batteries</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>On my first 2 years of University, I developed two Scientific Research projects focused on Graphene Batteries.  Graphene is this relatively new nanomaterial, which is composed of only carbon atoms arranged in a hexagonal shape which then form a sheet of one atom in thickness. It's an extremely strong material, that is also a very good electrical and thermal conductor. Besides, it can be extracted from natural Graphite using low-cost methods, making it a great candidate as a substitute for Lithium electrical batteries. The only drawback is that current methods for Graphene synthesis still can't produce large quantities of the material. <br/><br/> In my project, I've developed a new design for Galvanic cells using Graphene and Graphite as electrodes and an ionic solution for the electrolyte. The project's objective was to compare how these cells perform against other known metals. For this, I measured some parameters like: Voltage, Current, Temperature, Gases emmited and Corrosion of the cell. The case for the cells was 3D printed, and all the Graphene was synthetized on the lab using natural Graphite from Brazillians mines.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/ic.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/cpbr2019.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Volunteer at Campus Party Brasil 12</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>This was a very fun experience for me as it was the first time I went to a Campus Party (CPBR) edition in São Paulo. I worked there as a volunteer, and during the event I acted as a support for the attending Communities to assist them in whatever they needed. Also, I helped in any presentations that I could as an assistant to the speaker during the session. Besides my work, the event was awesome and I had the opportunity to meet some famous people that I've always admire, and also get to know new technologies and companies that I have never heard of. That's the magic of Campus Party!</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Year year="2018" />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>RASArm</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>This was the first project I've made at IEEE UFABC. When I got into the IEEE Robotics and Automation Society (RAS) as a volunteer, there was a demand for a MeArm project to be made. So I got a MeArm MDF Kit and assembled it along with some other volunteers. As an additional feature to this controlled robotic arm, I added some memory functionality, so it would also move on its own.<br/><br/> The project used a chipKit UNO32 as the microcontroller and a joystick to control the arm. The arm has its movement provided by 4 micro servo motors, and it's powered by a 12v power supply. The project had two main operation modes: The Control Mode, which the arm only moves when it receives the proper signal from the joystick, and the Memory Mode, in which it memorizes a limited number of steps and then repeats them in a cyclic fashion.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <ReactPlayer
  //             url="https://www.youtube.com/watch?v=hJYk24TG3_c"
  //           />
  //         )}
  //       />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/iniciaras.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Instructor at Fundamental Electronics Classes</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>Another project I took in my first year of Uni, was an electronics class we held every quarter. This consisted of a paid course made available for all the students who wanted to get a basic to intermediate undestanding about electricity, electronic components, analog and digital circuits and microcontrollers like Arduino. <br/><br/> My previous experience in robotics competitions allowed me to be an instructor at this course, so I could help on developing the content for the classes, as well as teaching on some of the topics and guiding the students on the bench experiments we usually made at the end of the classes.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Year year="2017" />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Brazilian Rocket Festival: MOBFOG</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>In Brazil we have the Brazilian Astronomy Olympics (OBA) and the Brazilian Rocket Festival (MOBFOG) both of which I had the chance of participating in school. The Astronomy Olympics was a theorical test with questions related to Aeronautics, Astronomy, Mathematics and Physics. On the other hand, MOBFOG was a practical competition, where teams of 3-4 students had to build a Vinager and Baking Soda rocket and launch it to reach the longest horizontal distance possible. <br/><br/> In the year of 2017, me and my friends were able to build the rocket as well as the rocket's base from scratch and make it to land 210m from the launching point. This got us to go to Barra do Piraí-RJ to compete with other successful teams at rocket launching. In this competition we actually got the Bronze trophy, which we then brought back to the school.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/mobfog2017.jpeg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //       <this.Year year="2016" />
  //       <this.Milestone 
  //         left={(
  //           <img src={`images/obr2016.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //         right={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>Brazilian Robotics Competition</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>Robotics championships have taken a big role for me on High School. I participated at the Brazilian Robotics Competition during 3 years, and yet on every edition I learned something new to incorporate on my soft and hard skills. This competition consists a path in which a line follower robot needs to avoid obstacles, make turns and overcome bumps in order to save the 'victims'. The victims are some aluminium balls located randomly on the last room, the robot then needs to identify them and place these victims on a safe place. <br/><br/> In the year of 2016, our team actually got the first place on the regional and state phases, getting the chance to participate on the national stage of the championship. This national phase took place in Recife-PE, where we competed with one hundred of the best teams from other schools in Brazil. At this point we were able to get the 11º position by the end of the event.</h4>
  //           </div>
  //         )}
  //       />
  //       <this.Year year="2014" />
  //       <this.Milestone 
  //         left={(
  //           <div className="row d-flex flex-column">
  //             <h1 style={{textAlign:'left'}}><b>First Lego League</b></h1>
  //             <h4 style={{textAlign:'left', fontSize:20}}>My very first robotics experience!! I remember to this day how excited I was when I got home to tell my mother I was selected to be at the Robotics Team at school. The championship itself consisted of a table with a variety of challenges and missions that our robot had to overcome. The more missions your robot completed on time, the more points your team got on that round. <br/><br/> The robot itself was created using the Mindstorm kits from LEGO which consisted of a 'brain' (generally NXT, or EV3 for newer kits); actuators in the form of different motors; a wide range of sensors (ultrassonic, infrared, touch, color sensors, etc); and also general structural parts. Therefore the design, programming and strategy was entirely the Team's responsability and the combination of all this factors resuts in a good or a bad robot for the table.</h4>
  //           </div>
  //         )}
  //         right={(
  //           <img src={`images/fll2014.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
  //         )}
  //       />
  //     </div>


      // <div className="row d-flex justify-content-center tm-mobile">
      //   <this.YearMobile year="2021" />
      //   <this.MilestoneMobile
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Chair at IEEE UFABC Student Branch</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>By the end of 2020/Beginning of 2021 I became chair of the IEEE UFABC Student Branch. For that, I was responsible for managing the technical and non-technical activities from the seven different Student Chapters that we had (each Chapter represents a IEEE Society and has an unique technical focus). <br /><br /> The duration of my term as the Branch leader took the whole year which has brought me many challenges as well as life-long lessons. During my tenure I had the opportunity to organize and/or conduct webinars to approximate the university students to companies (made possible by our partnership with <a href="#">Empower Talents</a>). I've also was able to work on the Branch internal organization by setting up regular meetings and hangouts. Collaboration with other student groups and the University's administration was also crutial to participate in events and annual fairs. <br /><br /> It was really a challenging year for me, taking into account my responsabilities facing the the Student Branch's chair position, as well with my internship and along with other projects. However, all of this reflected on both: better time management skills and task setting skills for myself.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/ieee_meeting.jpeg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/sas_2.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>SAS Institute Internship</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>By december of 2020 I got an offer for an internship at SAS Institute, a World Wide software company that is focused on Data Analytics. I officialy started working at SAS by January 5th of the following year, and since then I've already had wonderful experiences and countless growth opportunities (both personal and professional growth). <br /><br /> At SAS I'm part of the Digital Transformation and Innovation team, in which we're responsible for developing assets (e.g Demos, documentation) and spreading awareness about new technologies trends inside and outside SAS. Thus, we work on exploring technologies, tools, frameworks and commercial strategies for Data Analysis, IoT, Edge Computing, visual computing and NLP, aimed at incorporating these into the company's activities in Latin America.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/3d_printer_1.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>3D Printer Project</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>As I was excited about adding more CAD/CAM to my personal projects and also taking into account the absence of a 3D printer available for use in my hometown, I then decided to build my own DIY Low-cost 3D Printer. It shouldn't be too difficult as I had already seeing a lot of these 3D printers beeing made from old DVD Drivers and Arduino on Youtube. <br/><br/>So in January of 2021 I finished my first mini 3D Printer using mostly recycled materials. The structure was made of wood and the stepper motors were recycled from old DVD/CD drivers, for the electronics I used an Arduino Mega together with a RAMPS V4 board and standart A4988 stepper motor drivers. <br/><br/>For the software part, the project was based on the Marlin's firmware for interpreting the G-Code and controlling the RAMPS board. The slicer that worked better for me was Cura's that besides being Open-Source, it allows you to setup the print with a variety of different settings (like Adherence Layer and Filling Pattern). Finally, to close up the technology stack I used Pronterface to send the G-Code commands to the Arduino.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.YearMobile year="2020" />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/ras2019.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Chair at IEEE Robotics and Automation Society</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>Starting fresh the 2020's year, I was starting my tenure as the Chair for the IEEE Robotics and Automation Student Branch Chapter in my University. In this chapter, we perform technical and social activities that involve all the subjects related to robotics and automation: Microcontrollers, IoT, Computer Vision, Programming, Electronics, etc. <br/><br/>As the active chair, I was able to manage and participate as well in the majority of our technical projects, besides organizing non-technical activities like webinars, workshops and courses. Furthermore, I had the opportunity to represent the student branch on various events from our University and from IEEE (e.g RSS - South Section Student Branches Meeting).</h4>
      //       </div>
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>COVIData</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>At the beginning of the 2020 quarantine, together with colleagues and professors, I started the COVIData platform that helps in the identification of suspected cases of the new Coronavirus in the Great ABC Paulista region. This project was carried out in partnership with UFABC and the Intermunicipal Consortium of the Great ABC. <br/><br/>In this same project, I developed the mobile app "COVIData em Campo", together with the city hall of São Bernardo do Campo to carry out household surveys and coronavirus tests to the city's population. As a third application we developed, under the COVIData's umbrella, a internal portal for internal use by the UFABC's staff and students in order to perform Corona virus tests and to report symptoms.<br/><br/>All of these projects were a great success reaching about 8,000 people from the ABC's Region, helping on gathering and analysing data about the pandemic on the cities, and also spreading knowledge about safety measures to those who most needed it. Besides that, the usage of the platform internally at UFABC, collected some crutial data about the pandemic status on the university community, which contributed to the decision-making process for the university's administration.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/covidata.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/robotieee.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>RobotIEEE</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>The Robotics Competitions and Science Olimpics that I've participated during my High School years had a big impact on shaping who I am today. I have it clear in my mind that getting a degree on robotics engineering and making the kind of projects I make were heavily influenced by my Hobbies and extracurricular activities during my time at school. <br/><br/>However, at college I realized that these kind of opportunities weren't avalible for a lot of public schools in Brazil, so I decided to do something about it. Together with my friends at IEEE, and also building on top of another school-partnership project we've made on the previous year (see AstroIEEE), we developed a project called RobotIEEE: in which we trained High Schools students from Amaral Wagner public school, to be able to participate on the Brazilian Robotics Olimpics (OBR).<br/><br/>Therefore, we developed didactic materials tackling on every subject covered by the theorical tests on the competition (Physics, Mathematics, Biology, Phylosophy, ...), and we've also made content/webinars about practical robotics knowledge focused on building as well as coding a Line-Follower robot for the practical part of the competition.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Shawee MegaHack 3.0</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>Looking to participate on my first Hackathon, I came across with one of the biggest hackthons in Brazil: The Mega Hack - organized by Shawee. In this competition, we faced the Ambev's challenge on how to stand out as a brand and attract consumers back to bars. Basically, our challenge was make a MVP that would help to innovate and differentiate Ambev by creating immersive and unique experiences that go beyond product and take the spirit of the brand, understanding who the consumers are, their behavior and their journey. <br/><br/>Having this challenge in mind, me and my team were able to develop a fully functional mobile app (Android & IPhone) in which customers could scan the barcode from Ambev's products and retrieve a handful of information about it. With the app, anyone could check the ingredients of the product, nutritional information or even recommendations of other beverages based on the customer's preferences.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/megahack.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/ufair.png`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>NASA Space Apps Hackathon</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>The second Hackathon I've ever participated was the International Space Apps Challenge from NASA. This version of the 2020 challenge was focused on finding creative sollutions and projects to put the huge amount of public environmental data from NASA datasets, and make it easier to access for researchers, companies and the general public. <br/><br/>Our project won the Global Finalist award and its purpose was to demonstrate the correlation between our actions in everyday life and the atmospheric pollution on a local and global scale. For that we developed a website using Streamlit (web framework for data science websites using Python), in which anyone could choose two seemingly unrelated parameters (E.g: The amount of CO2 on the atmosphere and the number of reported floodings), then check how they're correlated making use of different types of visualizations - Heat maps, Bubble maps and Correlation matrices.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>XMas Tree: A Holiday's project</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>In order to start my <a href="#">Instructables'</a> portfolio, I made this simple and fun project for the Holidays in 2020. The "ArduXmas" is a Bluetooth controlled Christmas tree which uses some Neopixel LEDs to create lighting animations, just like a regular tree only you can control it with your smartphone! <br/><br/> This project uses a Arduino Nano for controling the lights; a HC-06 Bluetooth module for receiving commands; WS2812b Neopixel LED strip for lighting and the Blynk app that is well-known for developing IoT mobile applications. You can check more about how I made this project on my <a href="#">Instructables page</a>.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <ReactPlayer
      //         className="img-fluid"
      //         url="https://www.youtube.com/watch?v=amsoag3gSV0"
      //       />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/atable_anim.gif`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>ATable: The interactive periodic table</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>Following the IoT trend and thinking about what to do with the remaining Neopixel LEDs from my previous project, I've decided to build a interactive Periodic Table as my final project of the year! I was always facinated by the amount of 'hidden' information the periodic table contains simply by organizing its elements into rows, columns, families and groups. So in this project, I thought it would be really cool if I could light up each element individually and create heat-maps/animations in order to show various properties like: Electronegativity, Atomic mass, Phase transition, Year of Discovery, etc. <br/><br/> To make this 'ATable' I've used a ESP32 as the project's brain. The ESP32 is a powerful NodeMCU-based 32-bit microcontroller that has built-in wifi and bluetooth, making it the perfect choice for IoT applications! To control the table, the microcontroller would create its own wifi network in which you could connect using your phone or computer, and then access a HTML webpage that I built to choose one of various animations for the ATable to display.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.YearMobile year="2019" />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>IEEE National Student Branches Meeting</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>At this point, since 2018, I've participated in a variety of projects and activities in my IEEE University's Student Branch. The RNR (Student Branches National Meeting) is a annual event in which IEEE groups from all over the country gather up to share experiences, participate on contests, collaborate and learn together. <br/><br/> At this 2019-edition, I had the opportunity to get to know a lot of interesting people from other student branches, as well as their projects and ideas. At this meeting, we also received a lot of awards regarding our own Success Cases, a couple of which I've had the opportunity to contribute a great deal on that year.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/rnr2019.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/astroieee.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>AstroIEEE</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>Just like the other project I've mentioned (RobotIEEE) this one was heavily influenced by my own experiences in High School competitions. This project consisted of mentoring students from a public school to be able to participate in the Brazilian Astronomy Olympics (OBA) and  the Brazilian Rocket Festival (MOBFOG). <br/><br/> In this project I was able to conduct weekly workshops and classes for the High Schoolers, teaching them some basic concepts for building a high-quality Vinager and Baking Soda Rocket. I teached the fundamentals about: Stoichiometry for calculating the chemical reactions; Aerodinamics for the various Rocket parts; Safety & DIY skills for them to be able to build their rockets using only household materials. This project was a success on its own, where one of the teams was able to get to the National phase of the festival, getting the chance to travel to the Rio de Janeiro's state and competing with teams from all over the country.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Research on Graphite & Graphene Electrolithic Batteries</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>On my first 2 years of University, I developed two Scientific Research projects focused on Graphene Batteries.  Graphene is this relatively new nanomaterial, which is composed of only carbon atoms arranged in a hexagons which then form a sheet of one atom in thickness. It's an extremely strong material, that additionally is a very good electrical and thermal conductor. Also, it can be extracted from natural Graphite using low-cost methods, making it a great candidate as a substitute for Lithium electrical batteries. The only drawback is that current methods for Graphene synthesis still can't produce large quantities of the material. <br/><br/> In my project, I've developed a new design for Galvanic cells using Graphene and Graphite as electrodes and an ionic solution for the electrolyte. The project's objective was to compare how these cells perform against other known metals. For this, I measured some parameters like: Voltage, Current, Temperature, Gases emmited and Corrosion of the cell. The case for the cells was 3D printed, and all the Graphene was synthetized on the lab using natural Graphite from Brazillians mines.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/ic.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/cpbr2019.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Volunteer at Campus Party Brasil 12</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>This was a very fun experience for me as it was the first time I went to a Campus Party (CPBR) edition in São Paulo. I worked there as a volunteer, and during the event I acted as a support for the attending Communities to assist them in whatever they needed. Also, I helped in any presentations that I could as an assistant to the speaker during the session. Besides my work, the event was awesome and I had the opportunity to meet some famous people that I've always admire, and also get to know new technologies and companies that I have never heard of. That's the magic of Campus Party!</h4>
      //       </div>
      //     )}
      //   />
      //   <this.YearMobile year="2018" />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>RASArm</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>This was the first project I've made at IEEE UFABC. When I got into the IEEE Robotics and Automation Society (RAS) as a volunteer, there was a demand for a MeArm project to be made. So I got a MeArm MDF Kit and assembled it along with some other volunteers. As an additional feature to this controlled robotic arm, I added some memory functionality, so it would also move on its own.<br/><br/> The project used a chipKit UNO32 as the microcontroller and a joystick to control the arm. The arm has its movement provided by 4 micro servo motors, and it's powered by a 12v power supply. The project had two main operation modes: The Control Mode, which the arm only moves when it receives the proper signal from the joystick, and the Memory Mode, in which it memorizes a limited number of steps and then repeats them in a cyclic fashion.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <ReactPlayer
      //         className="img-fluid"
      //         url="https://www.youtube.com/watch?v=hJYk24TG3_c"
      //       />
      //     )}
      //   />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/iniciaras.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Instructor at Fundamental Electronics Classes</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>Another project I took in my first year of Uni, was an electronics class we held every quarter. This consisted of a paid course made available for all the students who wanted to get a basic to intermediate undestanding about electricity, electronic components, analog and digital circuits and microcontrollers like Arduino. <br/><br/> My previous experience in robotics competitions allowed me to be an instructor at this course, so I could help on developing the content for the classes, as well as teaching on some of the topics and guiding the students on the bench experiments we usually made at the end of the classes.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.YearMobile year="2017" />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Brazilian Rocket Festival: MOBFOG</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>In Brazil we have the Brazilian Astronomy Olympics (OBA) and the Brazilian Rocket Festival (MOBFOG) both of which I had the chance of participating in school. The Astronomy Olympics was a theorical test with questions related to Aeronautics, Astronomy, Mathematics and Physics. On the other hand, MOBFOG was a practical competition, where teams of 3-4 students had to build a Vinager and Baking Soda rocket and launch it to reach the longest horizontal distance possible. <br/><br/> In the year of 2017, me and my friends were able to build the rocket as well as the rocket's base from scratch and make it to land 210m from the launching point. This got us to go to Barra do Piraí-RJ to compete with other successful teams at rocket launching. In this competition we actually got the Bronze trophy, which we then brought back to the school.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/mobfog2017.jpeg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      //   <this.YearMobile year="2016" />
      //   <this.MilestoneMobile 
      //     image={(
      //       <img src={`images/obr2016.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>Brazilian Robotics Competition</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>Robotics championships have taken a big role for me on High School. I participated at the Brazilian Robotics Competition during 3 years, and yet on every edition I learned something new to incorporate on my soft and hard skills. This competition consists a path in which a line follower robot needs to avoid obstacles, make turns and overcome bumps in order to save the 'victims'. The victims are some aluminium balls located randomly on the last room, the robot then needs to identify them and place these victims on a safe place. <br/><br/> In the year of 2016, our team actually got the first place on the regional and state phases, getting the chance to participate on the national stage of the championship. This national phase took place in Recife-PE, where we competed with one hundred of the best teams from other schools in Brazil. At this point we were able to get the 11º position by the end of the event.</h4>
      //       </div>
      //     )}
      //   />
      //   <this.YearMobile year="2014" />
      //   <this.MilestoneMobile 
      //     title={(
      //       <div className="row d-flex flex-column">
      //         <h1 style={{textAlign:'left'}}><b>First Lego League</b></h1>
      //       </div>
      //     )}
      //     description={(
      //       <div className="row d-flex flex-column">
      //         <h4 style={{textAlign:'left', fontSize:20}}>My very first robotics experience!! I remember to this day how excited I was when I got home to tell my mother I was selected to be at the Robotics Team at school. The championship itself consisted of a table with a variety of challenges and missions that our robot had to overcome. The more missions your robot completed on time, the more points your team got on that round. <br/><br/> The robot itself was created using the Mindstorm kits from LEGO which consisted of a 'brain' (generally NXT, or EV3 for newer kits); actuators in the form of different motors; a wide range of sensors (ultrassonic, infrared, touch, color sensors, etc); and also general structural parts. Therefore the design, programming and strategy was entirely the Team's responsability and the combination of all this factors resuts in a good or a bad robot for the table.</h4>
      //       </div>
      //     )}
      //     image={(
      //       <img src={`images/fll2014.jpg`} className="img-fluid"  alt='image' style={{maxHeight:500, borderRadius:20}} />
      //     )}
      //   />
      // </div>
    //   </>
    // );
  // }
}

export default TimelineTest;