import React from "react";

// Importing utilities
import { renderContent } from '../utilities'

// Importing content
import content_2021 from '../Content/2021.json'
import content_2020 from '../Content/2020.json'

class Timeline extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      year: 2021,
      content: content_2021
    };
  }

  toogleYear(op){
    let { year } = this.state;
    if(op === 1){
      if(year < 2021) this.setState({year: ++year}, () => this.switchContent())
    }
    else{
      if(year > 2015) this.setState({year: --year}, () => this.switchContent())
    }
  }

  switchContent(){
    switch(this.state.year){
      case 2021:
        this.setState({content: content_2021});
        break;
      case 2020:
        this.setState({content: content_2020});
        break;
      default:
        break;
    }
  }

  render(){
    return (
      <div className="row d-flex justify-content-center">
      <div className="col-lg-7 col-md-7 col-sm-12 my-5 p-0" style={{backgroundColor:'white', paddingTop:20, paddingBottom:20}}>
        
        <div className="row d-flex flwx-column align-items-center justify-content-center m-0 p-0">
          <div 
            className="btn col-4 d-flex align-items-center justify-content-start p-5"
            onClick={() => this.toogleYear(1)}>
            <span className="glyphicon glyphicon-chevron-left" style={{fontSize:30}} />
          </div>
          <div className="col-4 d-flex align-items-center justify-content-center">
            <h1><b>{this.state.year}</b></h1>
          </div>
          <div 
            className="btn col-4 d-flex align-items-center justify-content-end p-5"
            onClick={() => this.toogleYear(-1)}>
            <span className="glyphicon glyphicon-chevron-right" style={{fontSize:30}} />
          </div>
        </div>

        <div className="col-12 px-5 d-flex flex-column justify-content-center">
          {renderContent(this.state.content)}
        </div>

      </div>
      </div>
    );
  }
}

export default Timeline;